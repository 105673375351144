
.button {
    background: #1287A5;
    border: 0;
    box-shadow: none;
    color: #FFF;
    cursor: pointer;
    display: block;
    font-size: 16px;
    margin: 0 auto;
    padding: 8px 16px;
    text-align: center;
    margin: 4px;
    width: 100px;
}